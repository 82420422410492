import { useMediaQuery, useTheme } from '@mui/material'

/**
 * Hook to check if the current screen size can be considered mobile
 * @returns True if viewport is mobile, false otherwise
 */
export function useMobile() {
    const theme = useTheme()
    return useMediaQuery(theme.breakpoints.down('sm'))
}

/**
 * Hook to check if the current screen size can be considered tablet
 * @returns True if viewport is tablet, false otherwise
 */
export function useTablet() {
    return useMediaQuery(`@media (min-width: 768px) and (max-width: 1279px)`)
}

/**
 * Hook to check if the current screen size can be considered desktop
 * @returns True if viewport is desktop, false otherwise
 */
export function useDesktop() {
    const theme = useTheme()
    return useMediaQuery(theme.breakpoints.up('lg'))
}

/**
 * Hook to check if the header is on the screen
 * @returns True if app should use header, false otherwise
 */
export function useHeader() {
    const theme = useTheme()
    return useMediaQuery(theme.breakpoints.down('lg'))
}

export {}
