/**
 * Layout component used in every page as main guide
 */

import React, {
    createContext,
    RefObject,
    useEffect,
    useRef,
    useState,
} from 'react'

import '../../fonts/fonts.css'
import './layout.css'

import { Grid, responsiveFontSizes, Theme } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { createStyles, makeStyles } from '@mui/styles'
import { createTheme, StyledEngineProvider } from '@mui/material/styles'
import '../../helpers/FontLoader'
import Header from '../Header/Header'
import SideBar from '../SideBar/SideBar'
import { useHeader } from '../../utilities/hooks'
import { useFonts } from '../../helpers/FontLoader'

let theme = createTheme({
    typography: {
        fontFamily: 'inherit',
    },
    palette: {
        primary: {
            main: '#0066cc',
        },
        secondary: {
            main: '#ffd600',
        },
        action: {
            active: '#d50000',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                rounded: { borderRadius: 8 },
            },
        },
    },
})
theme = responsiveFontSizes(theme)

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        app: {
            maxWidth: 1546,
            margin: '0 auto',
            '& *::selection': {
                background: `${theme.palette.secondary.main}80`,
            },

            '& a': {
                color: theme.palette.secondary.contrastText,
                backgroundColor: theme.palette.secondary.light,
            },

            '& em': {
                color: theme.palette.primary.main,
                fontWeight: 500,
                fontStyle: 'normal',
            },
        },

        // Used in LayoutWithAlternateSidebar
        navigation: {
            zIndex: 9,
            position: 'fixed',
            [theme.breakpoints.down('lg')]: {
                width: '100%',
            },
            [theme.breakpoints.up('lg')]: {
                // width: theme.spacing(48),
                padding: theme.spacing(2),
            },
            // height: '100vh',
        },
        // contentWrapper: {
        //     padding: theme.spacing(2),
        //     [theme.breakpoints.down('lg')]: {
        //         paddingTop: theme.spacing(3),
        //     },
        //     [theme.breakpoints.up('lg')]: {
        //         paddingLeft: theme.spacing(50),
        //     },
        // },
    })
)

type LayoutContext = {
    navigationRef: RefObject<HTMLDivElement | null>
    fontsLoaded: boolean
}
export const LayoutContext = createContext<LayoutContext>({} as LayoutContext)
const LayoutWithTheme: React.FC<React.PropsWithChildren<any>> = ({
    children,
}: React.PropsWithChildren<any>) => {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <LayoutWithAlternateSidebar>
                    {children}
                </LayoutWithAlternateSidebar>
            </ThemeProvider>
        </StyledEngineProvider>
    )
}

// const Layout: React.FC<React.PropsWithChildren<any>> = ({
//     children,
// }: React.PropsWithChildren<any>) => {
//     const styles = useStyles()
//     const theme = useTheme()
//
//     return (
//         <div className={styles.app}>
//             <Header />
//             <section
//                 style={{
//                     padding: `${theme.spacing(4)}px ${theme.spacing(2)}px`,
//                     maxWidth: 1200 + theme.spacing(4),
//                     margin: '0 auto',
//                 }}
//             >
//                 {children}
//             </section>
//         </div>
//     )
// }

const LayoutWithAlternateSidebar: React.FC<React.PropsWithChildren<any>> = ({
    children,
}: React.PropsWithChildren<any>) => {
    const styles = useStyles()

    const shouldUseHeader = useHeader()
    const fontsLoaded = useFonts()

    const navigationRef = useRef<HTMLDivElement>(null)
    const context = {
        navigationRef,
        fontsLoaded,
    }

    const [mounted, setMounted] = useState(false)
    useEffect(() => {
        setMounted(true)
    }, [])

    return (
        <>
            {mounted && (
                <LayoutContext.Provider value={context}>
                    <Grid container className={styles.app}>
                        <Grid
                            item
                            xs={12}
                            lg={'auto'}
                            className={styles.navigation}
                            ref={navigationRef}
                        >
                            {shouldUseHeader ? <Header /> : <SideBar />}
                        </Grid>
                        <Grid item xs={12} lg>
                            {children}
                        </Grid>
                    </Grid>
                </LayoutContext.Provider>
            )}
        </>
    )
}

export default LayoutWithTheme
