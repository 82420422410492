import { useEffect, useState } from 'react'

const FontFaceObserver = require('fontfaceobserver')

const montserratFont = new FontFaceObserver('Montserrat')

export function useFonts() {
    const [loaded, setLoaded] = useState(false)

    useEffect(() => {
        montserratFont.load().then(
            () => {
                document.body.classList.add('fonts-loaded')
                console.info('Montserrat font loaded')
                setLoaded(true)
            },
            () => {
                console.info('Montserrat font not available')
            }
        )
    }, [])

    return loaded
}
